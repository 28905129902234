<template lang="html">
  <v-container grid-list-md align-center justify-center pb-0 pt-0 mt-3>
    <v-toolbar
      id="sub-menu-static"
      height="40"
      color="transparent"
      style="display: flex;width:100%;overflow:auto"
    >
      <template>
        <v-list-tile
          v-for="item in items"
          :key="item.title"
          :to="item.path == '' ? '' : item.path"
          :class="tab === item.value ? 'active-menu' : ''"
        >
          <div style="white-space:nowrap !important">
            <span class="jc-c font-sub-menu" style="font-size:18px;">{{
              item.title
            }}</span>
          </div>
        </v-list-tile>
      </template>
    </v-toolbar>
    <v-content class="box-content" style="">
      <v-container pa-0 justify-center align-center flex fill-height>
        <v-layout justify-center align-center fill-height>
          <router-view @activeMenu="activeMenu" />
        </v-layout>
      </v-container>
    </v-content>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      // listMenu: ['สถิติ','แผนที่', 'ชนิดไม้ปลูกสูงสุด 15 อันดับ'],
      listMenu: ["สถิติรายจังหวัด", "ชนิดไม้ปลูกสูงสุด 15 อันดับ"],
      active: "",
      items: [
        { title: "สถิติรายจังหวัด", path: "/statistic", value: "statistic" },
        {
          title: "สถิติรายหน่วยงาน",
          path: "/statistic/department",
          value: "department",
        },
        // { title: 'แผนที่', path: '/statistic/map', value: 'map' },
        {
          title: "ชนิดไม้ที่ลงทะเบียนสูงสุด 15 อันดับ",
          path: "/statistic/plant_top",
          value: "plant_top",
        },
      ],
      tab: "",
    };
  },
  created() {},
  methods: {
    gotoLink(link) {
      this.$router.push(link);
    },
    activeMenu(status) {
      // console.log(status);
      this.tab = status;
    },
  },
};
</script>

<style scoped>
.listmenu {
  justify-content: center;
  display: flex;
  width: 100%;
}

.active-menu a.v-list__tile {
  border-bottom: 1px solid pink !important;
}
</style>
