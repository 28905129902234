<template lang="html">
  <v-container align-center justify-center pt-0 pl-0 pr-0 pb-0>
    <ComponentProject @activeMenu="activeMenu" />
  </v-container>
</template>

<script>
import ComponentProject from "@/components/ComponentProject";
export default {
  components: {
    ComponentProject,
  },
  data() {
    return {
      active: "",
    };
  },
  methods: {
    gotoLink(link) {
      this.$router.push(link);
    },
    activeMenu(status) {
      this.$emit("activeMenu", status);
    },
  },
};
</script>
