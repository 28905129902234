import { render, staticRenderFns } from "./MainStatistic.vue?vue&type=template&id=0b6f29d6&scoped=true&lang=html&"
import script from "./MainStatistic.vue?vue&type=script&lang=js&"
export * from "./MainStatistic.vue?vue&type=script&lang=js&"
import style0 from "./MainStatistic.vue?vue&type=style&index=0&id=0b6f29d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b6f29d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/builds/rfd/seeding_72/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
installComponents(component, {VContainer,VContent,VLayout,VListTile,VToolbar})
